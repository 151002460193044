import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createSafeLocalStorage } from 'safer-web-storage';
import { Loader } from './components/Loader';
import { Connect } from './Connect';
import {
  Wallet,
  MetaMaskError,
  getWallet,
  connectAnotherWallet,
  connectWallet,
  getMMProvider,
  switchChain,
} from './metaMask';

type AccountContextValue = {
  wallet: Wallet;
  connect: () => Promise<void>;
  logOut: () => void;
  switchChain: () => Promise<void>;
  forcedLogout: boolean;
};

const safeLocalStorage = createSafeLocalStorage();
const FORCED_LOGOUT_KEY = 'metamask-forced-logout';

const AccountContext = React.createContext<AccountContextValue | undefined>(
  undefined,
);

export function AccountProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [wallet, setWallet] = useState<Wallet>({ code: MetaMaskError.INITIAL });
  const [forcedLogout, setForcedLogout] = useState<boolean>(
    JSON.parse(safeLocalStorage.getItem(FORCED_LOGOUT_KEY)) || false,
  );

  useEffect(() => {
    async function init() {
      setLoading(true);

      setWallet(await getWallet());

      setLoading(false);
    }

    init();

    const provider = getMMProvider();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    provider?.on('connect', (...arguments_: unknown[]) =>
      console.log('connect', ...arguments_),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    provider?.on('disconnect', (...arguments_: unknown[]) =>
      console.log('disconnect', ...arguments_),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    provider?.on('chainChanged', init);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    provider?.on('accountsChanged', init);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      provider?.off?.('chainChanged', init);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      provider?.off?.('accountsChanged', init);
    };
  }, []);

  useEffect(() => {
    safeLocalStorage.setItem(FORCED_LOGOUT_KEY, JSON.stringify(forcedLogout));
  }, [forcedLogout]);

  async function connect() {
    await (forcedLogout
      ? connectAnotherWallet(wallet.provider!)
      : connectWallet(wallet.provider!));

    setForcedLogout(false);
  }

  function logOut(): void {
    setForcedLogout(true);
  }

  async function _switchChain() {
    if (wallet.provider) {
      await switchChain(wallet.provider);
    }
  }

  if (isLoading || !wallet) {
    return <Loader />;
  }

  const value = {
    wallet,
    connect,
    logOut,
    forcedLogout,
    switchChain: _switchChain,
  };

  return (
    <AccountContext.Provider value={value}>
      {wallet.code === 'CONNECTED' ? children : <Connect />}
    </AccountContext.Provider>
  );
}

export function useAccount(): AccountContextValue {
  const context = React.useContext(AccountContext);

  if (!context) {
    throw new Error('"useAccount" must be used within a "AccountProvider"');
  }

  return context;
}
