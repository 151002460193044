import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';

export const MAINNET_CHAIN_ID = '0x1';
export const GOERLI_CHAIN_ID = '0x5';

export const requiredChainId =
  process.env.NODE_ENV === 'production' ? MAINNET_CHAIN_ID : GOERLI_CHAIN_ID;

export enum MetaMaskError {
  'INITIAL' = 'INITIAL',
  'NO_METAMASK' = 'NO_METAMASK',
  'MULTIPLE_WALLETS' = 'MULTIPLE_WALLETS',
  'WALLET_NOT_CONNECTED' = 'WALLET_NOT_CONNECTED',
  'WRONG_CHAIN' = 'WRONG_CHAIN',
  'CONNECTED' = 'CONNECTED',
}

export type Wallet = {
  code: MetaMaskError;
  provider?: ethers.providers.ExternalProvider;
  account?: string;
  keys?: number[];
};

interface MMError extends Error {
  code?: number;
}

export async function getWallet(): Promise<Wallet> {
  let provider = (await detectEthereumProvider({
    mustBeMetaMask: true,
  })) as ethers.providers.ExternalProvider | undefined;

  if (window?.ethereum?.providers) {
    console.warn('Multiple providers detected');

    provider = getMMProvider();
  }

  if (!provider) {
    return {
      code: MetaMaskError.NO_METAMASK,
    };
  }

  // If the provider returned by detectEthereumProvider is not the same as
  // window.ethereum, something is overwriting it, perhaps another wallet.
  if (!window?.ethereum?.providers && provider !== window.ethereum) {
    console.error('Do you have multiple wallets installed?');

    return {
      code: MetaMaskError.MULTIPLE_WALLETS,
    };
  }

  const [accounts, chainId] = await Promise.all([
    provider?.request?.({ method: 'eth_accounts' }),
    provider?.request?.({ method: 'eth_chainId' }),
  ]);
  const account = accounts?.[0];

  if (!account) {
    return {
      code: MetaMaskError.WALLET_NOT_CONNECTED,
      provider,
    };
  }

  if (chainId !== requiredChainId) {
    return {
      code: MetaMaskError.WRONG_CHAIN,
      account,
      provider,
    };
  }

  return {
    code: MetaMaskError.CONNECTED,
    provider,
    account,
    keys: [],
  };
}

export async function connectWallet(
  provider: ethers.providers.ExternalProvider,
): Promise<void> {
  try {
    await provider.request?.({
      method: 'eth_requestAccounts',
    });
  } catch (error) {
    console.error('Failed to connect account', error);
  }
}

export async function connectAnotherWallet(
  provider: ethers.providers.ExternalProvider,
): Promise<void> {
  try {
    await provider.request?.({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
  } catch (error) {
    console.error('Failed to connect another account', error);
  }
}

export async function switchChain(
  provider: ethers.providers.ExternalProvider,
): Promise<void> {
  try {
    await provider.request?.({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: requiredChainId }],
    });
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((error as MMError).code === 4902) {
      console.error(`No Mainnet in MetaMask`, error);
    }

    console.error('Failed to switch network', error);
  }
}

export function getMMProvider(): ethers.providers.ExternalProvider | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const provider = window.ethereum?.providers
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ethereum?.providers?.find((p) => p.isMetaMask)
    : window.ethereum;

  if (provider) return provider as ethers.providers.ExternalProvider;

  return;
}
