import { CallOverrides, ethers } from 'ethers';
import contractMeta from './abi/contract.json';
import contractArtifact from './abi/Immutacord.json';
import { requiredChainId } from './metaMask';

interface MMError extends Error {
  method?: string;
  reason?: string;
}

export const contractAddress = contractMeta[requiredChainId];

export class Contract {
  private contractRead: ethers.Contract;
  private contractWrite: ethers.Contract;
  public ethersProvider: ethers.providers.Web3Provider;

  constructor(provider: ethers.providers.ExternalProvider) {
    this.ethersProvider = new ethers.providers.Web3Provider(provider);
    const signer = this.ethersProvider.getSigner();

    this.contractRead = new ethers.Contract(
      contractAddress,
      contractArtifact.abi,
      this.ethersProvider,
    );
    this.contractWrite = new ethers.Contract(
      contractAddress,
      contractArtifact.abi,
      signer,
    );
  }

  async getTokenId(id: string): Promise<number> {
    try {
      const tokenId = await this.contractRead.getMessageByHash(id);
      console.warn('Message has already been minted', {
        tokenId: Number(tokenId),
      });
      return Number(tokenId);
    } catch (error) {
      console.warn(
        `${(error as MMError).method}: ${(error as MMError).reason}`,
      );

      return 0;
    }
  }

  async mint(id: string, cid: string, signature: string) {
    try {
      const [mintPrice, freeMessages, totalSupply] = await Promise.all([
        this.contractRead.MINT_PRICE().catch(() => {
          return;
        }),
        this.contractRead.FREE_MESSAGES().catch(() => {
          return;
        }),
        this.contractRead.totalSupply().catch(() => {
          return;
        }),
      ]);

      const isFree = Number(freeMessages) - (Number(totalSupply) + 1) >= 0;

      const payload: CallOverrides = {
        value: isFree ? 0 : mintPrice,
      };

      const arguments_ = [id, cid, signature, payload];

      const estimatedGasLimit = Number(
        await this.contractWrite.estimateGas.mint(...arguments_),
      );

      if (estimatedGasLimit) {
        payload.gasLimit = estimatedGasLimit + 10;
      }

      return await this.contractWrite.mint(...arguments_);
    } catch (error) {
      console.error(
        `${(error as MMError).method}: ${(error as MMError).reason}`,
      );
    }
  }
}
