import { Link } from 'react-router-dom';
import illustration from '../assets/images/undraw_page_not_found.svg';

export function NotFound() {
  return (
    <main>
      <section className='min-vh-100 bg-primary-3 text-light py-5 o-hidden'>
        <div className='container position-relative layer-2'>
          <div className='row justify-content-center py-3'>
            <div className='col text-center'>
              <img
                src={illustration}
                alt='Mint Discord Message'
                className='illustration-connect py-5'
              />
              <h2>Message not found</h2>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8 col-md-10 text-center'>
              <div className='d-md-flex mb-3 justify-content-center'>
                <Link to='/' className='mx-1 btn btn-primary blue'>
                  Back home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
