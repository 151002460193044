import { ReactNode, AnchorHTMLAttributes } from 'react';
import SVG from 'react-inlinesvg';
import {
  MAINNET_CHAIN_ID,
  MetaMaskError,
  requiredChainId,
  switchChain,
} from './metaMask';
// import './assets/theme-cryptocurrency.css';
import './assets/custom.css';
import illustration from './assets/images/undraw_ethereum_re.svg';
import metaMaskLogo from './assets/images/mm-logo-fox.svg';
import { useAccount } from './AccountContext';
import { sendEvent } from './analytics';

export function Connect(): JSX.Element {
  const { wallet, connect, forcedLogout } = useAccount();

  if (wallet.code === 'NO_METAMASK') {
    return (
      <EmptyState
        heading='Install MetaMask to get started'
        subheading='We haven’t been able to detect MetaMask extension in your browser'
        linkProps={{
          href: 'https://metamask.io/download.html',
          target: '_blank',
          rel: 'noreferrer',
        }}
        btnContent={
          <>
            <SVG src={metaMaskLogo} className='injected-svg icon icon-lg' />
            <span>Get MetaMask</span>
          </>
        }
        btnOnClick={() => {
          sendEvent('button_clicked', {
            buttonId: 'get_metamask',
          });
        }}
      />
    );
  }

  if (wallet.code === MetaMaskError.MULTIPLE_WALLETS) {
    return (
      <EmptyState
        heading='We can‘t access your MetaMask wallet'
        subheading='Do you have multiple wallets installed?'
      />
    );
  }

  if (wallet.code === MetaMaskError.WALLET_NOT_CONNECTED || forcedLogout) {
    return (
      <EmptyState
        heading='Connect your wallet to mint Discord message'
        btnContent='Connect wallet'
        btnOnClick={() => {
          sendEvent('button_clicked', {
            buttonId: 'connect_wallet',
          });

          connect();
        }}
      />
    );
  }

  if (wallet.code === MetaMaskError.WRONG_CHAIN) {
    return (
      <EmptyState
        heading='To continue you need to be connected to Mainnet'
        btnContent={`Switch to ${
          requiredChainId === MAINNET_CHAIN_ID ? 'Mainnet' : 'Goerli'
        }`}
        btnOnClick={() => {
          sendEvent('button_clicked', {
            buttonId: 'switch_network',
          });

          switchChain(wallet.provider!);
        }}
      />
    );
  }

  if (wallet.code !== MetaMaskError.CONNECTED) {
    return <EmptyState heading='Something went wrong. Unknown state' />;
  }

  return <></>;
}

type EmptyStateProperties = {
  heading: string;
  subheading?: string | ReactNode;
  linkProps?: React.DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >;
  btnContent?: string | ReactNode;
  btnOnClick?: () => unknown;
};

function EmptyState({
  heading,
  subheading,
  btnContent,
  btnOnClick,
  linkProps,
}: EmptyStateProperties): JSX.Element {
  return (
    <main>
      <section className='min-vh-100 bg-primary-3 text-light py-5 o-hidden'>
        <div className='container position-relative layer-2'>
          <div className='row justify-content-center py-5'>
            <div className='col text-center'>
              <img
                src={illustration}
                alt='Mint Discord Message'
                className='illustration-connect py-5'
              />
              <h2>{heading}</h2>
              <h4 className='text-muted'>{subheading}</h4>
            </div>
          </div>
          {btnContent ? (
            <div className='row justify-content-center'>
              <div className='col-xl-6 col-lg-8 col-md-10 text-center'>
                <div className='d-md-flex mb-3 justify-content-center'>
                  {linkProps ? (
                    <a
                      {...linkProps}
                      className='mx-1 btn btn-primary btn-lg blue'
                    >
                      {btnContent}
                    </a>
                  ) : (
                    <button
                      type='button'
                      className='mx-1 btn btn-primary btn-lg blue'
                      onClick={btnOnClick}
                    >
                      {btnContent}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </main>
  );
}
