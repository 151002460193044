import { useParams } from 'react-router-dom';
import {
  DiscordDefaultOptions,
  DiscordMarkdown,
  DiscordMention,
  DiscordMessage,
  DiscordMessages,
  DiscordOptionsContext,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@discord-message-components/react';
import { Ref, useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useScreenshot } from 'use-screenshot-hook';
import { getMMProvider } from './metaMask';
import { Contract } from './contract';
import { Loader } from './components/Loader';
import { NotFound } from './components/NotFound';
import {
  formatEtherscanTxUrl,
  formatOpenSeaUrl,
  shortenHash,
} from './formatters';

import '@discord-message-components/react/dist/style.css';
import decoration from './assets/images/deco-blob-2.svg';
import doneCircle from './assets/images/done-circle.svg';
import loadingSvg from './assets/images/loading.svg';
import './assets/theme-cryptocurrency.css';
import './assets/custom.css';
import { usePageView } from './hooks/usePageView';
import { sendEvent } from './analytics';

let contract: Contract | undefined;
const provider = getMMProvider();

interface MessageData {
  guildId: string;
  message: {
    guildName: string;
    createdTimestamp: string;
    content: string;
    author: {
      id: string;
      bot: boolean;
      tag: string;
      username: string;
      displayAvatarURL: string;
    };
    mentions: Record<string, { author: string }>;
    edited: boolean;
  };
}

export function Message() {
  usePageView();

  const { messageId } = useParams();
  const [isLoadingMessage, setIsLoadingMessage] = useState(true);
  const [messageData, setMessageData] = useState<MessageData | null>(null);
  const [minting, setMinting] = useState<boolean>(false);
  const reference = useRef<HTMLDivElement>(null);
  const modalReference = useRef<HTMLDivElement>(null);
  const { image, takeScreenshot } = useScreenshot({ ref: reference });
  const [tx, setTx] = useState<string>();
  const [tokenId, setTokenId] = useState<number>();

  useEffect(() => {
    async function getMessage() {
      setIsLoadingMessage(true);

      try {
        const result = await window
          .fetch(`/api/messages/${messageId}`)
          .then((r) => {
            if (r.ok) return r.json();

            throw new Error(`${r.status} ${r.statusText}`);
          });

        setMessageData(result);
      } catch (error) {
        console.error('Failed to fetch message:', error);
      }

      setIsLoadingMessage(false);
    }

    async function checkMessageMinted() {
      if (!messageId) return;

      if (!provider) return;

      contract = new Contract(provider);
      const id = await contract.getTokenId(messageId);

      if (id) {
        setTokenId(id);
      }
    }

    getMessage();
    checkMessageMinted();
  }, [messageId]);

  useEffect(() => {
    if (!contract || !tx) return;

    contract.ethersProvider.once(tx, async () => {
      if (!contract || !messageId) return;

      const tId = await contract.getTokenId(messageId);

      setTokenId(tId);

      await window.fetch(`/api/messages/${messageId}/created/${tId}`, {
        method: 'POST',
      });

      sendEvent('message_minted', {
        messageId,
        tokenId: tId,
      });

      setMinting(false);
    });
  }, [tx]);

  useEffect(() => {
    if (modalReference?.current) {
      $(modalReference.current).on('shown.bs.modal', () => {
        takeScreenshot('png');
      });
    }
  }, [isLoadingMessage, modalReference.current]);

  if (isLoadingMessage) {
    return <Loader />;
  }

  if (!messageData || !messageId) {
    return <NotFound />;
  }

  const isLoadingModal = Boolean(minting || !image);

  return (
    <>
      <section className='bg-primary-3 o-hidden'>
        <div className='container layer-1'>
          <div className='row justify-content-between'>
            <div className='col-xl-5 col-md-6 text-light'>
              <h1 className='display-4'>Mint a Discord message on Ethereum</h1>
              <p className='lead'>
                Mint this message with Immutacord for a&nbsp;nominal fee, then
                it's stored on the blockchain forever!
              </p>
            </div>
            <div className='col-xl-5 col-md-6'>
              <div className='card card-body shadow-lg'>
                <div className='row'>
                  <div className='col-12 mb-4'>
                    <div data-active className='row'>
                      <div className='col'>
                        <RenderMessage messageData={messageData} />

                        <h4 className='text-primary text-center mb-2'>
                          Metadata
                        </h4>
                        <div className='mb-0'>
                          <strong>Discord server name:</strong>{' '}
                          <code>{messageData.message.guildName}</code>
                        </div>
                        <div className='mb-0'>
                          <strong>Discord server ID:</strong>{' '}
                          <code>{messageData.guildId}</code>
                        </div>
                        <div className='mb-0'>
                          <strong>Message author name:</strong>{' '}
                          <code>{messageData.message.author.tag}</code>
                        </div>
                        <div className='mb-0'>
                          <strong>Message author ID:</strong>{' '}
                          <code>{messageData.message.author.id}</code>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {tokenId ? (
                  <>
                    <p>
                      <strong>This message has already been minted.</strong>
                    </p>
                    <p>
                      Check it out on{' '}
                      <a
                        href={formatOpenSeaUrl(tokenId)}
                        className='hover-arrow'
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        OpenSea
                      </a>
                    </p>
                  </>
                ) : (
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={minting}
                    data-toggle='modal'
                    data-target='#mint-message-modal'
                    onClick={() => {
                      sendEvent('button_clicked', {
                        buttonId: 'open_mint_modal',
                      });
                    }}
                  >
                    Mint Discord message
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className='modal fade'
        id='mint-message-modal'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
        data-backdrop='static'
        data-keyboard='false'
        ref={modalReference}
      >
        <div
          className={`modal-dialog ${tokenId ? 'modal-md' : 'modal-md'}`}
          role='document'
        >
          <div className='modal-content bg-dark border-0 text-light'>
            <div className='modal-body'>
              {tokenId ? (
                <SuccessModalBody tokenId={tokenId} />
              ) : (
                <div className='m-3'>
                  <RenderMessage
                    messageData={messageData}
                    innerRef={reference}
                    renderedImage={image}
                  />
                  {minting && tx ? (
                    <div className='text-center mb-4'>
                      Transaction on Etherscan:{' '}
                      <a
                        href={formatEtherscanTxUrl(tx)}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        {shortenHash(tx)}
                      </a>
                    </div>
                  ) : null}
                  <div className='d-flex justify-content-end'>
                    <button
                      className='m-1 btn btn-secondary'
                      type='button'
                      data-dismiss='modal'
                      disabled={minting}
                    >
                      Close
                    </button>
                    <button
                      className={[
                        'm-1 btn btn-primary',
                        minting && 'btn-loading btn-loading-animate',
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      type='button'
                      disabled={isLoadingModal}
                      onClick={async () => {
                        if (!contract || !image) return;

                        sendEvent('button_clicked', {
                          buttonId: 'mint',
                        });

                        setMinting(true);

                        const { cid, signature } = await uploadFile(
                          messageId,
                          image,
                        );

                        const result = await contract.mint(
                          messageId,
                          cid,
                          signature,
                        );

                        if (result?.hash) {
                          setTx(result.hash);
                        } else {
                          setMinting(false);
                        }
                      }}
                    >
                      {minting ? (
                        <>
                          <SVG className='injected-svg icon' src={loadingSvg} />
                          <span>Minting</span>
                        </>
                      ) : (
                        'Mint'
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='decoration-wrapper d-none d-md-block'>
        <div className='decoration top right scale-4'>
          <SVG className='injected-svg bg-primary-2' src={decoration} />
        </div>
      </div>
    </>
  );
}

function RenderMessage({
  messageData,
  innerRef,
  renderedImage,
}: {
  messageData: MessageData;
  innerRef?: Ref<HTMLDivElement>;
  renderedImage?: string;
}) {
  return (
    <div ref={innerRef} className='mb-4'>
      {renderedImage ? (
        <img src={renderedImage} />
      ) : (
        <DiscordOptionsContext.Provider
          value={{
            ...DiscordDefaultOptions,
            profiles: messageData.message.mentions,
          }}
        >
          <DiscordMessages>
            <DiscordMessage
              author={messageData.message.author.username}
              avatar={messageData.message.author.displayAvatarURL}
              bot={messageData.message.author.bot}
              timestamp={new Date(messageData.message.createdTimestamp)}
              edited={messageData.message.edited}
            >
              {renderMessage(messageData.message.content)}
            </DiscordMessage>
          </DiscordMessages>
        </DiscordOptionsContext.Provider>
      )}
    </div>
  );
}

function SuccessModalBody({ tokenId }: { tokenId: number }) {
  return (
    <div className='m-xl-4 m-3'>
      <div className='icon-round icon-round-lg bg-blue mx-auto mb-4'>
        <SVG className='icon' src={doneCircle} data-inject-svg />
      </div>
      <div className='text-center mb-4'>
        <h4 className='h3'>Minted</h4>
        <div>
          Check out Discord message #{tokenId}{' '}
          <a
            href={formatOpenSeaUrl(tokenId)}
            className='hover-arrow'
            target='_blank'
            rel='noreferrer noopener'
          >
            on OpenSea
          </a>
        </div>
        <div>
          <button
            className='mt-3 btn btn-secondary'
            type='button'
            data-dismiss='modal'
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

function dataURItoBlob(dataURI: string) {
  const [header, data] = dataURI.split(',');
  const { groups } = header.match(/data:(?<type>.+);base64/) ?? {};
  const type = groups?.type;

  if (!type) {
    throw new Error('Invalid data URI');
  }

  const binary = window.atob(data);

  const array = [];

  for (let index = 0; index < binary.length; index++) {
    // eslint-disable-next-line unicorn/prefer-code-point
    array.push(binary.charCodeAt(index));
  }
  return {
    blob: new Blob([new Uint8Array(array)], { type }),
    type,
  };
}

async function uploadFile(id: string, imageDataUri: string) {
  const { blob, type } = dataURItoBlob(imageDataUri);

  const formData = new FormData();
  formData.append('file', blob, `${id}.${type.split('/')[1]}`);

  const response: { ok: boolean; cid: string; signature: string } = await window
    .fetch(`/api/messages/${id}/image`, {
      method: 'POST',
      body: formData,
    })
    .then((r) => r.json());

  return response;
}

function renderMessage(str: string) {
  const parts = str.split(/(<@\d+>)/);

  return parts.map((part, i) => {
    if (/<@\d+>/.test(part)) {
      const { userId } = part.match(/<@(?<userId>\d+)>/)?.groups ?? {};

      return <DiscordMention profile={userId} key={i} />;
    }

    return <DiscordMarkdown key={i}>{part}</DiscordMarkdown>;
  });
}
