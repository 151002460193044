import { init, track } from '@amplitude/analytics-browser';
import { requiredChainId } from './metaMask';

export function iniAnalytics() {
  init('de54881c32620d4a70cf8ce8cf2d3b63');
}

export async function sendEvent(
  eventName: string,
  attributes?: Record<string, unknown>,
) {
  track(eventName, {
    chainId: requiredChainId,
    ...attributes,
  });
}
