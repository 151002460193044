import { useEffect } from 'react';
import {
  Params,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { sendEvent } from '../analytics';

export function usePageView() {
  const { pathname } = useLocation();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const serverId = searchParams.get('serverId');

    sendEvent('page_view', {
      page_path: cleanPathname(pathname, params),
      discordServerId: serverId,
      params,
    });

    if (serverId) {
      setSearchParams({});
    }
  }, [pathname]);
}

function cleanPathname(pathname: string, params: Params<string>) {
  for (const [key, value] of Object.entries(params)) {
    pathname = pathname.replace(value as string, `:${key}`);
  }

  return pathname.replace(/\/$/, '') || '/';
}
