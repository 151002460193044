import * as ReactDOM from 'react-dom/client';
import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { AccountProvider } from './AccountContext';
import { iniAnalytics } from './analytics';
import { Home } from './Home';
import { Message } from './Message';
import logo from './assets/images/logo.png';
import twitter from './assets/images/twitter.svg';
import opensea from './assets/images/opensea.svg';

const rootElement = document.querySelector('#root');

if (!rootElement) {
  throw new Error('Root element not found');
}

iniAnalytics();

const root = ReactDOM.createRoot(rootElement);

function Header() {
  return (
    <div className='navbar-container bg-primary-3'>
      <nav
        className='navbar navbar-expand-lg justify-content-between navbar-dark border-bottom-0 bg-primary-3'
        data-sticky='top'
      >
        <div className='container'>
          <div className='col flex-fill px-0 d-flex justify-content-between align-items-center'>
            <Link className='navbar-brand mr-0 fade-page logo' to='/'>
              <img src={logo} alt='Immutacord logo' height={50} />
              Immutacord
            </Link>
            <ul className='nav social-btns'>
              <li className='nav-item'>
                <a
                  href='https://twitter.com/immutacord'
                  target='_blank'
                  className='nav-link'
                  title='Twitter'
                >
                  <SVG className='data-inject-svg' height={32} src={twitter} />
                </a>
              </li>
              <li className='nav-item'>
                <a
                  href='https://opensea.io/collection/immutacord'
                  target='_blank'
                  className='nav-link'
                  title='OpenSea'
                >
                  <SVG className='data-inject-svg' height={32} src={opensea} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route
        path='/'
        element={
          <>
            <Header />
            <Outlet />
          </>
        }
      >
        <Route index element={<Home />} />
        <Route
          path='/messages/:messageId'
          element={
            <AccountProvider>
              <Message />
            </AccountProvider>
          }
        />
      </Route>
      <Route path='*' element={<Home />} />
    </Routes>
  </BrowserRouter>,
);
