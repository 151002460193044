import { DISCORD_CLIENT_ID } from './constants';
import './assets/theme.css';
import './assets/custom.css';
import logo from './assets/images/undraw_quick_chat.svg';
import { usePageView } from './hooks/usePageView';
import { sendEvent } from './analytics';

export function Home() {
  usePageView();

  return (
    <main>
      <section className='min-vh-100 bg-primary-3 text-light py-5 o-hidden'>
        <div className='container position-relative layer-2'>
          <div className='row justify-content-center pb-5'>
            <div className='col text-center'>
              <img
                src={logo}
                alt='Mint Discord Message'
                className='illustration py-5'
              />
              <h2>
                To mint Discord messages on Ethereum,
                <br />
                install our Discord bot.
              </h2>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8 col-md-10 text-center'>
              <div className='d-md-flex mb-3 justify-content-center'>
                <a
                  href={`https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&permissions=0&scope=bot`}
                  target='_blank'
                >
                  <button
                    type='button'
                    className='mx-1 btn btn-primary btn-loading btn-lg blue'
                    onClick={() => {
                      sendEvent('button_clicked', {
                        buttonId: 'install_bot',
                      });
                    }}
                  >
                    <span>Install Discord Bot</span>
                  </button>
                </a>
              </div>

              <div className='text-muted py-5'>
                <div className='py-2'>
                  Once it's done,
                  <br />
                  reply to a message you want to mint with <code>
                    !mint
                  </code>{' '}
                  command
                </div>
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  className='demo-video p-2'
                >
                  <source src='/assets/mint-demo.mp4' type='video/mp4' />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
