import { contractAddress } from './contract';
import { MAINNET_CHAIN_ID, requiredChainId } from './metaMask';

export function shortenHash(hash: string): string {
  return hash.slice(0, 6) + '...' + hash.slice(-4);
}

export function formatEtherscanTxUrl(tx: string) {
  const baseUrl =
    requiredChainId === MAINNET_CHAIN_ID
      ? 'https://etherscan.io'
      : 'https://goerli.etherscan.io';

  return `${baseUrl}/tx/${tx}`;
}

export function formatOpenSeaUrl(tokenId: number) {
  return requiredChainId === MAINNET_CHAIN_ID
    ? `https://opensea.io/assets/ethereum/${contractAddress}/${tokenId}`
    : `https://testnets.opensea.io/assets/goerli/${contractAddress}/${tokenId}`;
}
